import React, { useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import { navigate } from 'gatsby'
import { Spinner } from '@chakra-ui/react'

import Box from '../../components/Box'
import Text from '../../components/Text'
import SeeMore from '../../components/SeeMore'
import Container from '../../components/Container'
import { responsive } from '../../contexts/responsive';
import BackgroundImage from '../../components/BackgroundImage'

import subscript from './subscription.svg'

const SubscriptionPage = () => {
  const [loading, setLoading] = useState()
  const [value, , remove] = useLocalStorage('email', false);
  useEffect(() => {
    return () => remove()
  }, [])
  useEffect(() => {
    if (value) {
      let formData = new FormData()
      formData.append('email', value)
      formData.append('date', new Date())
      fetch(process.env.GATSBY_SUBSCRIPTION, {
        method: 'POST',
        body: formData,
      }).then(() => setLoading(false))
    }
  }, [value])

  if (!value && typeof window !== 'undefined') {
    navigate('/contact')
    return null
  }

  return (
    <Box textAlign={'center'}>
      <Container pt={responsive('3.625em', '6.5em')} pb={responsive('2.875em', '4.375em')}>
        {loading ? (
          <Box pt="4em" textAlign="center">
            <Spinner />
          </Box>
        ) : (
          <>
            <Box mx="auto" width={responsive('4.875em', '14.88%')}>
              <BackgroundImage src={subscript} ratio={173.57 / 108.32} />
            </Box>
            <Text.Bold mt={responsive('0.625rem', '0.25em')} fontSize={responsive('1.75em', '3.75em')}>訂閱成功</Text.Bold>
            <Text mt={responsive('0.75rem', '0.67em')} color="custom.textGray" fontSize={responsive('0.875em', '1.875em')}>
              一期的「慢速溝通」特輯將在2022下半年寄至你的信箱！
            </Text>
          </>
        )}

      </Container>
      <SeeMore page="collab" />
    </Box>
  )
}

export default SubscriptionPage
